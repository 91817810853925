import tryRequest, { GetRequestOptions } from './tryRequest'
import {
  PublicGetVaultStatisticsParamsSchema,
  PublicGetVaultStatisticsResponseSchema,
} from './types/public.get_vault_statistics'

export default async function fetchVaultStatistics(
  options?: GetRequestOptions
): Promise<PublicGetVaultStatisticsResponseSchema> {
  return tryRequest<PublicGetVaultStatisticsParamsSchema, PublicGetVaultStatisticsResponseSchema>(
    '/public/get_vault_statistics',
    {},
    { ...options, useGet: true }
  )
}
