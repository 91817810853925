import tryRequest, { GetRequestOptions } from './tryRequest'
import {
  PublicGetVaultBalancesParamsSchema,
  PublicGetVaultBalancesResponseSchema,
} from './types/public.get_vault_balances'

export default async function fetchVaultBalances(
  params: PublicGetVaultBalancesParamsSchema,
  options?: GetRequestOptions
): Promise<PublicGetVaultBalancesResponseSchema> {
  return tryRequest<PublicGetVaultBalancesParamsSchema, PublicGetVaultBalancesResponseSchema>(
    '/public/get_vault_balances',
    params,
    { ...options, useGet: true }
  )
}
