import { DepositNetwork } from './chains'
import { YieldTokenAddresses } from './yield'

export const weETHCTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7ef0873bBf91B8Ecac22c0e9466b17c6Cc14B1bd',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x79AC9B13810D31066Be547EdA46C40264b39397D',
    symbol: 'weETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x9485b9d142E805B86F5B9224D1aCA0acFe6bdd58',
        vault: '0x236D36C52bC56a37d9094B424A1420BE21f6392c',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x452204A2a6c103D106c86bEb869Ca6EF705eD67C',
        vault: '0x76eef7cb3DAab7b23fB818E3D82997f38aa1A620',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xDf72bB31D759c4AB17fbBbc5D1e0D1081C4626BE',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x5f675ab715BB9D712e4628E74c8e11B46867aCe3',
        vault: '0xD0DEe9Fd4Cc1d2eCB4572c01cDC65603557cc506',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xfe051Cc322F5124e53cA89611dD0c9a32F350a1C',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x7378E8C016d1A512B976CE8Ab885C281b59a521A',
        vault: '0xD0DEe9Fd4Cc1d2eCB4572c01cDC65603557cc506',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xDf72bB31D759c4AB17fbBbc5D1e0D1081C4626BE',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0xbf89A191a7F3678Baaa44064632835E08A0657cA',
        vault: '0xF151509ABefb08EdaF4CFd95fE6B3Eecb7d5C158',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xfe051Cc322F5124e53cA89611dD0c9a32F350a1C',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x36d8bD1886cA09F476E6f15a15D1917FEB032CEB',
        vault: '0x21DaBaa157f2b43606f528098C922d66898C5645',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xD90CDCdf2DA81223AA2C088A81716f9EE7DFAe88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8FF3f8bc7884fe59425F090d5ec6A570472DfF88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
    ],
  },
}

export const weETHBULLTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7ef0873bBf91B8Ecac22c0e9466b17c6Cc14B1bd',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xfdb2a80b21Ed2D6D738e2a4221931A343675382A',
    symbol: 'weETHBULL',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x9485b9d142E805B86F5B9224D1aCA0acFe6bdd58',
        vault: '0x236D36C52bC56a37d9094B424A1420BE21f6392c',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x452204A2a6c103D106c86bEb869Ca6EF705eD67C',
        vault: '0x76eef7cb3DAab7b23fB818E3D82997f38aa1A620',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x00baf0AB12f45d6CEdC06e8b2062C64d2d1Ef896',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x1295Ce1D6A4e89905d8ecdce6887F68b46736419',
        vault: '0x6Da6DC63b996DED3cc313d7B20162fd8BCdeaAc0',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x3bA36e7e1d32ee2dba8AFAefaE55169e08c7f8db',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x6bAe8C82600C0741C7513BE451dF8Cc569921302',
        vault: '0x6Da6DC63b996DED3cc313d7B20162fd8BCdeaAc0',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x00baf0AB12f45d6CEdC06e8b2062C64d2d1Ef896',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0xb3BcFCFDFA906539D862e00c1b1BadfCA3DC257A',
        vault: '0x4513F3266b8552570ad9e139d0c252a7D06F1b7E',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x3bA36e7e1d32ee2dba8AFAefaE55169e08c7f8db',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0xd8E4b49A031964d022762d6f34Cc0d41D1167750',
        vault: '0x194A1B169800C0D6E248F7D0d305228b12D8e439',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xD90CDCdf2DA81223AA2C088A81716f9EE7DFAe88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8FF3f8bc7884fe59425F090d5ec6A570472DfF88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
    ],
  },
}

export const weETHCSTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7ef0873bBf91B8Ecac22c0e9466b17c6Cc14B1bd',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x4900D5BbB97cea689C301E1805837a78eeBD8573',
    symbol: 'weETHCS',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x9485b9d142E805B86F5B9224D1aCA0acFe6bdd58',
        vault: '0x236D36C52bC56a37d9094B424A1420BE21f6392c',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x452204A2a6c103D106c86bEb869Ca6EF705eD67C',
        vault: '0x76eef7cb3DAab7b23fB818E3D82997f38aa1A620',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x871D150B1A9Df599f8B5A153e0903037103b6a00',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xBb2969067AB7BD9A717883928AB0bE16E8Da1256',
        vault: '0x265736F3AB6825DC968a99fD25d2141d56E80744',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xbaC0328cd4Af53d52F9266Cdbd5bf46720320A20',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0x6220BE333a142177B9907521537f8C10A4Edf2B4',
        vault: '0x265736F3AB6825DC968a99fD25d2141d56E80744',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x871D150B1A9Df599f8B5A153e0903037103b6a00',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xB92f6bD634dCf04f6879457042B9B62D400fb393',
        vault: '0xfC129aAAF2374845a8E9BAfd91F6f79C1d85c215',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xbaC0328cd4Af53d52F9266Cdbd5bf46720320A20',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xF47065CD55D1Dc201f599A1E977b0aB7A2BdfCcf',
        vault: '0xbe750464c504CC543AF11830eC28C26470d11812',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xD90CDCdf2DA81223AA2C088A81716f9EE7DFAe88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8FF3f8bc7884fe59425F090d5ec6A570472DfF88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
    ],
  },
}

export const weETHBTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7ef0873bBf91B8Ecac22c0e9466b17c6Cc14B1bd',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x9ffB0Ee4447c491E3c842b62184BB0669A12b9e4',
    symbol: 'weETHB',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x9485b9d142E805B86F5B9224D1aCA0acFe6bdd58',
        vault: '0x236D36C52bC56a37d9094B424A1420BE21f6392c',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x452204A2a6c103D106c86bEb869Ca6EF705eD67C',
        vault: '0x76eef7cb3DAab7b23fB818E3D82997f38aa1A620',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x7b206Fa0dBF0Cb5b8c3D644C47BbdDd8858Ed2fb',
          symbol: 'weETHB',
          decimals: 18,
        },
        connector: '0x58e0A8Af1C3169AA331ad95967BF661eB08d49C4',
        vault: '0x1c32093c8987dF9B39dD569FFe51E30d0B32219B',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xDE9458C0750D0401cbD90678715dE6Cee2e1FD6D',
          symbol: 'weETHB',
          decimals: 18,
        },
        connector: '0xbfBCA5C94999794505201c42353d1C8216Abc003',
        vault: '0x1c32093c8987dF9B39dD569FFe51E30d0B32219B',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x7b206Fa0dBF0Cb5b8c3D644C47BbdDd8858Ed2fb',
          symbol: 'weETHB',
          decimals: 18,
        },
        connector: '0xB4cB0cBB907752865F61aB13893A4FeD569ed57F',
        vault: '0xDE1778Db568Dc33049Bc8E745D9b7d7e4C528118',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xDE9458C0750D0401cbD90678715dE6Cee2e1FD6D',
          symbol: 'weETHB',
          decimals: 18,
        },
        connector: '0x0C5c17fDf5B24F49d2483Eb6B78d8d958350D5e1',
        vault: '0x5630809011cb726370464F472b711d70B4e8920A',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xE0AcE05Ded5f3653aB0caAAf19E458DAb2E824F2',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xD90CDCdf2DA81223AA2C088A81716f9EE7DFAe88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x8467d28492bB13cC964C49Fcc2fCA8A8B478836E',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8FF3f8bc7884fe59425F090d5ec6A570472DfF88',
        vault: '0xbEc0B31bbfA62364EBF6e27454978E33c5d9F4eE',
      },
    ],
  },
}

export const rswETHCTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xa7456213A5c081F53fEb3A4F64b88A4637Bf3028',
    symbol: 'rswETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x797Db58F4c6611253e92B9a3260E3Cc9C69430a5',
    symbol: 'rswETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xadfe82e1fc0f59d87bc69be637b947758505a614',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0x7b4c44F13bd5d8F234DA1A0Af71e75FdB77b2C6E',
        vault: '0x47BB0e22F015BFc25138EfaF4261AB5CA4a887d6',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x0b3639A094854796E3b236DB08646ffd21C0B1B2',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0xCa80293391423d8B81850Ff051BAd3FcE936EbB7',
        vault: '0x6F054A18383b2EC7F709d7f24e69D9DbCC921aD3',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x8faC67324Ad5f24419C37192D586FE1E5293D83a',
          symbol: 'rswETHC',
          decimals: 18,
        },
        connector: '0x993eF5d9E4341AE0437a8F02dcA614687123acAB',
        vault: '0x6481400fb34eea4692E411229d37ceB6a2af63B2',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x8faC67324Ad5f24419C37192D586FE1E5293D83a',
          symbol: 'rswETHC',
          decimals: 18,
        },
        connector: '0x5De869E775B9c033F6AcD2bA287522347c6E9eeD',
        vault: '0x3E0906a5c68681C1EDc81e492f9F0Ff30C460F6b',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xadfe82e1fc0f59d87bc69be637b947758505a614',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0x486C887352E9AfE3d81D6643C65c1cf68A781B18',
        vault: '0x4508c67469009DA7c5c8A2901554A2195D4E5a5a',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x0b3639A094854796E3b236DB08646ffd21C0B1B2',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0xfe1cF1B6d7b1F96a90258FAd2dD3CD4a2770F604',
        vault: '0x4508c67469009DA7c5c8A2901554A2195D4E5a5a',
      },
    ],
  },
}

export const rsETHCTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xc3ad6f6E0940df79e12b30F307109fc4Ec2ED7D0',
    symbol: 'rsETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xD9f47BEBEe03C90855c209e06f884fa0551c1fDd',
    symbol: 'rsETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x8C4516853074c4Bb3bA921fE9e714aC322F7081C',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0xc0424453E78C6ee65c12329e8A908EfDEF54ABAa',
        vault: '0x62a11f238e489A49d840ec337c8e544cc43A7eAd',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xE11D1F74C0FC6c4Fe45BEAD7daB6CC8044C234C8',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0xedcA80caa863D1893EdE5E8764B6F4C34051d0E4',
        vault: '0x6961c56923eA4e8FD5450fE7bEE193744222C072',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xeCAAd20Fe81818E4Ce1EB35E463f681084ab2DD5',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x1C8Ee27B24408E611f895D470A5c134c5429e880',
        vault: '0x467c0835499271530A8ac05Eb7Df75dD26E2bFEe',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xCfD701E3540d769427Bc699C63Ac5940B703fe48',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0xdEdD7a99522c080e2f29Af974D0C8F533Eb2b8B8',
        vault: '0x467c0835499271530A8ac05Eb7Df75dD26E2bFEe',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xeCAAd20Fe81818E4Ce1EB35E463f681084ab2DD5',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x008Ef590487F188E23Cd17De7C55d9d906d6b039',
        vault: '0xBcB494059969DAaB460E0B5d4f5c2366aab79aa1',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xCfD701E3540d769427Bc699C63Ac5940B703fe48',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x8519e21355763572edD3DA1C19C71CEa99883e57',
        vault: '0x4fF761D46Be8100063e388Bec1cb3653C3832908',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x8C4516853074c4Bb3bA921fE9e714aC322F7081C',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x96EB7012Dad44CF191B5B7d7E72eF2699EC2B250',
        vault: '0xF99E92715024EF3CAAB0328107161ea405D61e01',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xE11D1F74C0FC6c4Fe45BEAD7daB6CC8044C234C8',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0xA57ADfCf24c9a1eca8F39958B2B025ce27F0b781',
        vault: '0xF99E92715024EF3CAAB0328107161ea405D61e01',
      },
    ],
  },
}

export const sUSDeBULLTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xd87d41f1e4d8b48F3cA18faFc1069b18F9c15B9d',
    symbol: 'sUSDe',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x4BAC0257C1a5be3814653789C6e60afF2F5f1DaD',
    symbol: 'sUSDeBULL',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xE820506a947396C20fA852AFc17CDf234b76e9a0',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x4722c480E9452f722716980FaDF77dCDf42bbc0f',
        vault: '0x9576aFF10dFdb1d24B507EEb88554dF3427E43D6',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xe21739C53fa561c5334Efd45c6cC3DB9ca99B5C3',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0xb3BcFCFDFA906539D862e00c1b1BadfCA3DC257A',
        vault: '0xB544dFE3BD399aa645fC12fd535f966dAa95e872',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x9D1525EE901CcE8C4224754a6204D2d5917C710c',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xd1BB3141E0B4dEb9d555fE052D26fEb51f3ab344',
        vault: '0x9d7Ae8fe3B7Dc5922019b71724f6C7F1438080A7',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xEbd2921149B72Bbb6BC6a950896e2d10dE9Ad2e2',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xBAb7809D2dF54D846ED13304A7090a5259E75F39',
        vault: '0x9d7Ae8fe3B7Dc5922019b71724f6C7F1438080A7',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x9D1525EE901CcE8C4224754a6204D2d5917C710c',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x86F1824A0106B97DD6BD16a049b497f4CAC93A6e',
        vault: '0xB293594803771a5184a63031224C8C5851064a41',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xEbd2921149B72Bbb6BC6a950896e2d10dE9Ad2e2',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xfC129aAAF2374845a8E9BAfd91F6f79C1d85c215',
        vault: '0x2f7b1ae31904e8b035718D80F404761135e51a17',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xE820506a947396C20fA852AFc17CDf234b76e9a0',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x70E2f773c1De017EEc73E84C523842593BD6Bc61',
        vault: '0x40fB94330Db4A4Bd3bAf8725211CDFa67d483439',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xe21739C53fa561c5334Efd45c6cC3DB9ca99B5C3',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x26f44E0F9025f7c42595F1B7b899edBA57277dcB',
        vault: '0x40fB94330Db4A4Bd3bAf8725211CDFa67d483439',
      },
    ],
  },
}

export const LBTCPSTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xDF129D5dc9c335B597815b1EBAB2BC0ff1af880c',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0x49B9C82582B9916dE295D98b0c55373c300BbaEa',
    symbol: 'LBTCPS',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xAFB6Bb95cd70D5367e2C39e9dbEb422B9815339D',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x2BBbB3f9495f6d6666648DFEFf7528748f255365',
        vault: '0xbaC0328cd4Af53d52F9266Cdbd5bf46720320A20',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x42B7B80518244D205Ba775a3B4D60Ec655b3f443',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x5129aA5f69338aaDF36cDd3c2bDd6575cf03F8E8',
        vault: '0xd7E44De9013935A30D87669467c7b0e5a6A7991F',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x0dDc91650583532D143539e71a337CE31A35233E',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0x1959175B5dAf736Bfe41bA5c927dceb5D6CE2696',
        vault: '0xFB452304c2896C7956299eE703F3C8218c470639',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x062F93b9bD9ceb50dcdb1230A9e89CBA36157C33',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0x48A920bf86f47b6629eEA9D0b36A993752A44fB4',
        vault: '0xFB452304c2896C7956299eE703F3C8218c470639',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x0dDc91650583532D143539e71a337CE31A35233E',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0x3f0A603602EFB90b37D69744AAaE0afe0647A508',
        vault: '0x6B1316f3DbF5a14243E1BA30e3DC9419f9E03701',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x062F93b9bD9ceb50dcdb1230A9e89CBA36157C33',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0xE0AbAb28676CC7d4BE7a6ECEF938411DdEe28269',
        vault: '0xD189a6CA6c67AbB04521a679CC4A94C5DFD4a0D5',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xAFB6Bb95cd70D5367e2C39e9dbEb422B9815339D',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0xF789D4dafc6Ee6068B645cE8B026097DB3C096DF',
        vault: '0xaC5Ef8aEB59B3A0d98Cb3d508BD8D29941E1cc67',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x42B7B80518244D205Ba775a3B4D60Ec655b3f443',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0x6294D58c2FEB7b71Df1057975E39D7f9Bb56c86f',
        vault: '0xaC5Ef8aEB59B3A0d98Cb3d508BD8D29941E1cc67',
      },
    ],
  },
}

export const LBTCCSTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xDF129D5dc9c335B597815b1EBAB2BC0ff1af880c',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0x65410Dd3A47f7cdfFd0486D45688F00B142029D7',
    symbol: 'LBTCCS',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xAFB6Bb95cd70D5367e2C39e9dbEb422B9815339D',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x2BBbB3f9495f6d6666648DFEFf7528748f255365',
        vault: '0xbaC0328cd4Af53d52F9266Cdbd5bf46720320A20',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x42B7B80518244D205Ba775a3B4D60Ec655b3f443',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x5129aA5f69338aaDF36cDd3c2bDd6575cf03F8E8',
        vault: '0xd7E44De9013935A30D87669467c7b0e5a6A7991F',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x845D0f36287DbA5F1Db0515584eC26C9Bbc84Ebd',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0xCCef516D71b4F8DE79A6b4507AeF7132d883fDb3',
        vault: '0xA4EEc0c2F44DEFf64ab5e9A96c688EF6930c2E12',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x84D8b20275724f31130F76Ecf42a501eDF72C1e0',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0x2B1E0d09468db771DD76C8D67Bd9fE3F1251CF98',
        vault: '0xA4EEc0c2F44DEFf64ab5e9A96c688EF6930c2E12',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x845D0f36287DbA5F1Db0515584eC26C9Bbc84Ebd',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0xff6A68d154AC8D92F8Cba92B8a54512a49eE1081',
        vault: '0x43ccF4a0ec4E31C35825C84cBca0a6a9243FBE8B',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x84D8b20275724f31130F76Ecf42a501eDF72C1e0',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0x3A3006B74C6c108A141f7EC3815b673B933D6dB7',
        vault: '0xB4cB0cBB907752865F61aB13893A4FeD569ed57F',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xAFB6Bb95cd70D5367e2C39e9dbEb422B9815339D',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0xF789D4dafc6Ee6068B645cE8B026097DB3C096DF',
        vault: '0xaC5Ef8aEB59B3A0d98Cb3d508BD8D29941E1cc67',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x42B7B80518244D205Ba775a3B4D60Ec655b3f443',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0x6294D58c2FEB7b71Df1057975E39D7f9Bb56c86f',
        vault: '0xaC5Ef8aEB59B3A0d98Cb3d508BD8D29941E1cc67',
      },
    ],
  },
}

export const LBTCBTestnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xDF129D5dc9c335B597815b1EBAB2BC0ff1af880c',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0x0Eb95740A626500f80E1652186E32416477880b5',
    symbol: 'LBTCB',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xAFB6Bb95cd70D5367e2C39e9dbEb422B9815339D',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x2BBbB3f9495f6d6666648DFEFf7528748f255365',
        vault: '0xbaC0328cd4Af53d52F9266Cdbd5bf46720320A20',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x42B7B80518244D205Ba775a3B4D60Ec655b3f443',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x5129aA5f69338aaDF36cDd3c2bDd6575cf03F8E8',
        vault: '0xd7E44De9013935A30D87669467c7b0e5a6A7991F',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x1405116418BeA756C3c3b09C1F42212Ac846E7F7',
          symbol: 'LBTCB',
          decimals: 8,
        },
        connector: '0x267C17ceA10d75B65Bd65Ed4f5D4375d1cEbb74E',
        vault: '0x4ed9599B50Beb4E496A7ffb87724122FDF180e47',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x22104a0Dd0c89A661c1D6e4F5883DC1D9e294b7D',
          symbol: 'LBTCB',
          decimals: 8,
        },
        connector: '0xE9DEad39Fe7bdf8380169aa25b76Fec7870B68C7',
        vault: '0x4ed9599B50Beb4E496A7ffb87724122FDF180e47',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x1405116418BeA756C3c3b09C1F42212Ac846E7F7',
          symbol: 'LBTCB',
          decimals: 8,
        },
        connector: '0xD189a6CA6c67AbB04521a679CC4A94C5DFD4a0D5',
        vault: '0x69C0Cf1Fc16F2545F9D27a567012d3bDB4609F26',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x22104a0Dd0c89A661c1D6e4F5883DC1D9e294b7D',
          symbol: 'LBTCB',
          decimals: 8,
        },
        connector: '0xb36fB5729DA0201E46a562Cbd4883D3F094d4F21',
        vault: '0xAC1b434ebe5E118B973c71F801da9780cbDCeC5A',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xAFB6Bb95cd70D5367e2C39e9dbEb422B9815339D',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0xF789D4dafc6Ee6068B645cE8B026097DB3C096DF',
        vault: '0xaC5Ef8aEB59B3A0d98Cb3d508BD8D29941E1cc67',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x42B7B80518244D205Ba775a3B4D60Ec655b3f443',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0x6294D58c2FEB7b71Df1057975E39D7f9Bb56c86f',
        vault: '0xaC5Ef8aEB59B3A0d98Cb3d508BD8D29941E1cc67',
      },
    ],
  },
}
