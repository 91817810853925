import { DepositNetwork } from './chains'
import { YieldTokenAddresses } from './yield'

export const LBTCBMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x36b5C126A3D7B25F6032653A0d18823Ee48a890e',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0x2104654d6Da663961a86AC3Cf1751981C5dc62E8',
    symbol: 'bLBTC',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x457379de638CAFeB1759a22457fe893b288E2e89',
        vault: '0x76624ff43D610F64177Bb9c194A2503642e9B803',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xecac9c5f704e954931349da37f60e39f515c11c1',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x8f1DceD9AA9E0da020D27fECB39e2fA2C8B9575d',
        vault: '0xB29D27DF122833aa38Da3eb816B0EFcEE09cdBA8',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xdFd366D941A51e1f53Fbddb19FB4eE3af17FF991',
          symbol: 'bLBTC',
          decimals: 8,
        },
        connector: '0x2E1245D57a304C7314687E529D610071628117f3',
        vault: '0xed4608e62576d96005B9d6c345F6Ef4986df56a4',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xA2bE759B86CeA53372C3e9a882047cdC3884D568',
          symbol: 'bLBTC',
          decimals: 8,
        },
        connector: '0xD8D4DbC9D6f8997ED7f3EC2890E1347c8E62cd52',
        vault: '0xed4608e62576d96005B9d6c345F6Ef4986df56a4',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xdFd366D941A51e1f53Fbddb19FB4eE3af17FF991',
          symbol: 'bLBTC',
          decimals: 8,
        },
        connector: '0x5E72430EC945CCc183c34e2860FFC2b5bac712c2',
        vault: '0x954bE1803546150bfd887c9ff70fd221F2F505d3',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xA2bE759B86CeA53372C3e9a882047cdC3884D568',
          symbol: 'bLBTC',
          decimals: 8,
        },
        connector: '0xe5Aa7b769Fd6169071E08a632E9550986BeBC398',
        vault: '0xF6D6547Ef09FeeA9DE905e6169d9367c2f41753A',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0x52CB41109b637F03B81b3FD6Dce4E3948b2F0923',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xecac9c5f704e954931349da37f60e39f515c11c1',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0xAB6ed8b53A2FECfA11968BcDC334B7696eE98477',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
    ],
  },
}

export const LBTCCSMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x36b5C126A3D7B25F6032653A0d18823Ee48a890e',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0xbCab1f8BbA323BC55EA8cfaC34edAcf8DBE92dD4',
    symbol: 'LBTCCS',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x457379de638CAFeB1759a22457fe893b288E2e89',
        vault: '0x76624ff43D610F64177Bb9c194A2503642e9B803',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xecac9c5f704e954931349da37f60e39f515c11c1',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x8f1DceD9AA9E0da020D27fECB39e2fA2C8B9575d',
        vault: '0xB29D27DF122833aa38Da3eb816B0EFcEE09cdBA8',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x5a27765DbE2476240B1265A305c2e3554fD3f341',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0xB0A797aa4E8A58a9C2cBF8477f686Fbe2C09D092',
        vault: '0x0da8090b6e3A25AfE78AB003E00f82DF8f161125',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x5a27765DbE2476240B1265A305c2e3554fD3f341',
          symbol: 'LBTCCS',
          decimals: 8,
        },
        connector: '0x2D733e70A377FcFc249d273095250762A93F3820',
        vault: '0x3083B6140eF4916a315162C32b8AA9261572cc4b',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0x52CB41109b637F03B81b3FD6Dce4E3948b2F0923',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xecac9c5f704e954931349da37f60e39f515c11c1',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0xAB6ed8b53A2FECfA11968BcDC334B7696eE98477',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
    ],
  },
}

export const LBTCPSMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x36b5C126A3D7B25F6032653A0d18823Ee48a890e',
    symbol: 'LBTC',
    decimals: 8,
  },
  lyraOutputToken: {
    address: '0x5Fc48A32437Ff4BBab2A22646c3c9344ba003971',
    symbol: 'LBTCPS',
    decimals: 8,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x457379de638CAFeB1759a22457fe893b288E2e89',
        vault: '0x76624ff43D610F64177Bb9c194A2503642e9B803',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xecac9c5f704e954931349da37f60e39f515c11c1',
          symbol: 'LBTC',
          decimals: 8,
        },
        connector: '0x8f1DceD9AA9E0da020D27fECB39e2fA2C8B9575d',
        vault: '0xB29D27DF122833aa38Da3eb816B0EFcEE09cdBA8',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x367711f0377867b51Fe53e30F5125a9A31d3D50b',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0x2B9984F1F979708CE229A6EBE0aaFbA5073C2045',
        vault: '0x47beB31d2Dd8d9853F03B230c16bF6B827c7B020',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x367711f0377867b51Fe53e30F5125a9A31d3D50b',
          symbol: 'LBTCPS',
          decimals: 8,
        },
        connector: '0xdb1c2F432e51824b33b9269C4b1Ff6190c1e5F35',
        vault: '0x52CB41109b637F03B81b3FD6Dce4E3948b2F0923',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x8236a87084f8B84306f72007F36F2618A5634494',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0x52CB41109b637F03B81b3FD6Dce4E3948b2F0923',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xecac9c5f704e954931349da37f60e39f515c11c1',
          symbol: 'LBTC',
          decimals: 18,
        },
        connector: '0xAB6ed8b53A2FECfA11968BcDC334B7696eE98477',
        vault: '0x5eFC527B2640681289E31E1e29f94EA397b6c589',
      },
    ],
  },
}

export const rsETHCMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xc47e2E800a9184cFbD274AC1eeCcCDF942715dB7',
    symbol: 'rsETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xd35bb8582809b4BDa4F8bCCE1bde48559f63eCbf',
    symbol: 'rsETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x388341d9E5A7D7d5accD738B2a31b0622E0c1b87',
        vault: '0x35d4D9bc79B0a543934b1769304B90d752691caD',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x87eEE96D50Fb761AD85B1c982d28A042169d61b1',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x497C4eFeeE525f6a76AC77584F7Ec2FA06504984',
        vault: '0x44ed9cE901B367B1EF9DDBD4974C82A514c50DEc',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xEDfa23602D0EC14714057867A78d01e94176BEA0',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0xeBB5D642aA8ccDeE98373D6aC3ee0602b63824b3',
        vault: '0xC4Cb2F82A01dC896a4d423231E60d7B500252e19',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x4186BFC76E2E237523CBC30FD220FE055156b41F',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x59729095f131Ff2cb4c3F647EdcfE26eE8dB6C61',
        vault: '0x486936FB1CE805e8C46E71C69256e72f3f550d38',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xF90b959ae8dfBa2DD793AD05176209835658362b',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0xc83994744a346CEf7C9224E531A57a4607996142',
        vault: '0x50F18D1377B9DFFb7db6A9579B81C3EF27756d57',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xB054a628027B974Cd1289eb0f73F47Bd0A1F010c',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x49c6194d7dc07828188C9CB5C992AAb4AA86cA6d',
        vault: '0x50F18D1377B9DFFb7db6A9579B81C3EF27756d57',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xd464170afe0eE2a4865B2ca6dBcc6dfB8f4Bf125',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x22D79d9E37248a9B1b97e6272119cCcAD037F167',
        vault: '0x50F18D1377B9DFFb7db6A9579B81C3EF27756d57',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x8F5d8a65D98925E10BA83b5C1C5c3BE100f7591B',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0xb3Fd0DEBaAAD6ac64813A4E5D651212ECEBfF4EB',
        vault: '0x50F18D1377B9DFFb7db6A9579B81C3EF27756d57',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xF90b959ae8dfBa2DD793AD05176209835658362b',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0xA72bc51f800127621d4Ab541E7Bb70B86Fe88F0F',
        vault: '0x4Dfa9966457EE49dCa3407f8DD484B739C048670',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xB054a628027B974Cd1289eb0f73F47Bd0A1F010c',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x30A6E6A3851c18aa67429ACC8a1DfAFE20A29FEb',
        vault: '0xeBB5D642aA8ccDeE98373D6aC3ee0602b63824b3',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xd464170afe0eE2a4865B2ca6dBcc6dfB8f4Bf125',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x59729095f131Ff2cb4c3F647EdcfE26eE8dB6C61',
        vault: '0xbfe7Cd69d3983299D3d18D1Ae5C411e1FF61A993',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x8F5d8a65D98925E10BA83b5C1C5c3BE100f7591B',
          symbol: 'rsETHC',
          decimals: 18,
        },
        connector: '0x15144f3014898AbDAD0D5777bFB303132525FfeD',
        vault: '0x2e9E50b883cF3240bDC40d5496b914Da5d544B86',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xA1290d69c65A6Fe4DF752f95823fae25cB99e5A7',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x4984D046EF87F9bCA7AE86558C567b898C4f6acD',
        vault: '0x6DCA2Cb269b7618B1F0D6195B5f0eAF3641b7136',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x87eEE96D50Fb761AD85B1c982d28A042169d61b1',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x16753c0227db5a3d346Ef14B9F9CB52089f83e0C',
        vault: '0x6DCA2Cb269b7618B1F0D6195B5f0eAF3641b7136',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xEDfa23602D0EC14714057867A78d01e94176BEA0',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0xD76d36056F4c2Bae3dE175E11163653b23CF51d3',
        vault: '0x6DCA2Cb269b7618B1F0D6195B5f0eAF3641b7136',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x4186BFC76E2E237523CBC30FD220FE055156b41F',
          symbol: 'rsETH',
          decimals: 18,
        },
        connector: '0x851e30395dcb2c8438cC245d7e28da7c7E23F870',
        vault: '0x6DCA2Cb269b7618B1F0D6195B5f0eAF3641b7136',
      },
    ],
  },
}

export const rswETHCMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xC419959850d49166C2d5250Ee89ff9910679D8c8',
    symbol: 'rswETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x5bbef94dcee8f087D5146d2815bC4955C76B2794',
    symbol: 'rswETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0xF15d420bE7b27F1fA0D9487105658EdC3C0EA508',
        vault: '0x4BB4C3CDc7562f08e9910A0C7D8bB7e108861eB4',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xE9A12fB15cC00b59867E4E2f0aCbdCebfd32b3d7',
          symbol: 'rswETHC',
          decimals: 18,
        },
        connector: '0x5da948DFf8dc37b9Dd4544bf4c738bD5725EdccC',
        vault: '0xeF7b53D59b5C6c4B5Aae3b2Fb9b1446EA087c7ce',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xE9A12fB15cC00b59867E4E2f0aCbdCebfd32b3d7',
          symbol: 'rswETHC',
          decimals: 18,
        },
        connector: '0xb1178803A726e2077947754de9f2f0cbdA29A60F',
        vault: '0x96E47fC9c701E8EEF22Fa53e62dc3eBBDcCAEa73',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xFAe103DC9cf190eD75350761e95403b7b8aFa6c0',
          symbol: 'rswETH',
          decimals: 18,
        },
        connector: '0x7447bbF1F7A8101fEC9c0DA5487dB607657A88A1',
        vault: '0x6Fde8a8194B6CF67ff33356448d06bD8f90C1eF1',
      },
    ],
  },
}

export const sUSDeBULLMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0xb82d12742c3728a14eaa43ebe1c0d32bb62216eb',
    symbol: 'sUSDe',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x0b4eD379da8eF4FCF06F697c5782CA7b4c3E505E',
    symbol: 'sUSDeBULL',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x9d39a5de30e57443bff2a8307a4256c8797a3497',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0xfa8c07E28461eb7c65b33De024DB97eE4C052C97',
        vault: '0xE3E96892D30E0ee1a8131BAf87c891201F7137bf',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x4BB4C3CDc7562f08e9910A0C7D8bB7e108861eB4',
        vault: '0x5324c6d731a3d9D740e880929E2c952bA27408De',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0xf7eFFD77BD355a9992A46B89d57800aA5eA96696',
        vault: '0xFE00C281729fa7E7AaB453690ed184284F51268C',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0xAc0c4B009efF095AD90c43a3B6dF097e835B0c62',
        vault: '0x3c143EA5eBaB50ad6D2B2d14FA719234d1d38F1b',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x1d080C689B930f9dEa69CB3B4Bc6b8c213DFC2ad',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xD4ab8AB74327D4dcfaf6b4B27593FC38b2F8aF5F',
        vault: '0xbF7591de71cAf166269744A370379B0178E3c732',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0xcfe8bc6297e693cbB58aD93A361e64a6D0E7CDeE',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x1Bc1b3772761e56DA7d108F369DC44Fb2C479E16',
        vault: '0xbF7591de71cAf166269744A370379B0178E3c732',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0x98f1AED814443d6DDD0a724B0f40098F517159Bc',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xCc8164e11Ffbcd7D341Cd1F8DE0f55Fd86C3B557',
        vault: '0xbF7591de71cAf166269744A370379B0178E3c732',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x81494d722DDceDbA31ac40F28daFa66b207f232B',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x4211Ff84a54B455c28dEc6e2B15324bA445959a9',
        vault: '0xbF7591de71cAf166269744A370379B0178E3c732',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x1d080C689B930f9dEa69CB3B4Bc6b8c213DFC2ad',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x12fBD04CB103c596B78110C70eEDF16821CBfcAE',
        vault: '0x988ddDb1c7578aFae302143b1c5fda12fD486904',
      },
      {
        network: DepositNetwork.Optimism,
        inputToken: {
          address: '0xcfe8bc6297e693cbB58aD93A361e64a6D0E7CDeE',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x4061DF0f966Da2E7d973F9f532df87DD7a38DF75',
        vault: '0x81ed5Dc90F708Dd908DccFfd5128B5C3405f74c5',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0x98f1AED814443d6DDD0a724B0f40098F517159Bc',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0x6FEf1bb8Ade9A836663d4c15AFd5985Fb545004f',
        vault: '0x504D7959511B3c1e8B76149869106D8Bd6FB51e2',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x81494d722DDceDbA31ac40F28daFa66b207f232B',
          symbol: 'sUSDeBULL',
          decimals: 18,
        },
        connector: '0xeB184dcae58290F5f3c5Ca1A32813ebd28b6079f',
        vault: '0x43b019139946466A010c936a85df14C17C4159c0',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x9d39a5de30e57443bff2a8307a4256c8797a3497',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x687E1c385740841a1a30eA53bF272664EDC57D9b',
        vault: '0x12A3674207E345c79e1AD67F62e3988e6bb06287',
      },
      {
        network: DepositNetwork.Optimism,
        outputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x941ce45a3ec4EC6c0d14AFa726af6c788fC5A717',
        vault: '0x12A3674207E345c79e1AD67F62e3988e6bb06287',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x7AF8EFDDCa5C70c49808615837478Ed6C94C9dc8',
        vault: '0x12A3674207E345c79e1AD67F62e3988e6bb06287',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x211cc4dd073734da055fbf44a2b4667d5e5fe5d2',
          symbol: 'sUSDe',
          decimals: 18,
        },
        connector: '0x82d599C4982301d3Ce26b2AdcB67602DFa0b9f23',
        vault: '0x12A3674207E345c79e1AD67F62e3988e6bb06287',
      },
    ],
  },
}

export const weETHBMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7B35b4c05a90Ea5f311AeC815BE4148b446a68a2',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x513Dc0e3407CA3A6E073A2f2d43fd61498db5739',
    symbol: 'bweETH',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xCc958F84DaF36d3eC20BcBee7E99C073B882efc3',
        vault: '0x8180EcCC825b692ef65FF099a0A387743788bf78',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8F8c29B0271D4c60435DaF148df4a84edcB21c98',
        vault: '0xF982c812099d03AFFa0c8062aa1abcb584c23329',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xBd282333710B9C7e33E8a37d027885A7C079Ae23',
        vault: '0x3FBFD80EF7591658d1D7DdEC067F413eFd6f985c',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x32390aD170c9604fa97A894C353a4511C0D4b4C2',
          symbol: 'bweETH',
          decimals: 18,
        },
        connector: '0x0055D66c146811D2db19bC89cc633Bac7eb8e53D',
        vault: '0x0DE591aAa133Ee843B4d86dA7EddD9e39d5B0E5e',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xA0B0b5ff5A45D054E2517CB1903C1713E199Fc55',
          symbol: 'bweETH',
          decimals: 18,
        },
        connector: '0x7C6a55289a683Deb6970690bd84550041F30358d',
        vault: '0x0DE591aAa133Ee843B4d86dA7EddD9e39d5B0E5e',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x254691C06Da387c1050C726cF498eFdA89083820',
          symbol: 'bweETH',
          decimals: 18,
        },
        connector: '0x84c2b50B64a3f43639A404b8A2b0fc6900e07967',
        vault: '0x0DE591aAa133Ee843B4d86dA7EddD9e39d5B0E5e',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x32390aD170c9604fa97A894C353a4511C0D4b4C2',
          symbol: 'bweETH',
          decimals: 18,
        },
        connector: '0xB4e78DAEaE4aA911f2427FF4af4B10AFe70D9891',
        vault: '0xc52EF06B0b44dA0Df03aD57197B78d784d12d8a6',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xA0B0b5ff5A45D054E2517CB1903C1713E199Fc55',
          symbol: 'bweETH',
          decimals: 18,
        },
        connector: '0xB592512153c22F5Ba573b0c3E04cAB99d4Cd8856',
        vault: '0x0b9F56Bf85566Fd7275948554A846F3af9cf8794',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x254691C06Da387c1050C726cF498eFdA89083820',
          symbol: 'bweETH',
          decimals: 18,
        },
        connector: '0xdE7F475fcbDdba6bd5546fDee8DB9B0639F486bb',
        vault: '0x30147A4989a0282aab8C9477aE9341dA4D09d3B1',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x1a22ec8Af98c39075afDf671a1e52bb569F74215',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xF6c475d2aB23d84e45AD3634C8956dCDe27315E0',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x6Ee9b6ad1c97AdeeD071fd5f349cE65f91e43333',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
    ],
  },
}

export const weETHBULLMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7B35b4c05a90Ea5f311AeC815BE4148b446a68a2',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xe48cdbe3A233Ea577c9ee6959801e7730e516d1A',
    symbol: 'weETHBULL',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xCc958F84DaF36d3eC20BcBee7E99C073B882efc3',
        vault: '0x8180EcCC825b692ef65FF099a0A387743788bf78',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8F8c29B0271D4c60435DaF148df4a84edcB21c98',
        vault: '0xF982c812099d03AFFa0c8062aa1abcb584c23329',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xBd282333710B9C7e33E8a37d027885A7C079Ae23',
        vault: '0x3FBFD80EF7591658d1D7DdEC067F413eFd6f985c',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x9A25A56ad60e3009bF0945233752eE0e445511f7',
        vault: '0xaDA48ab8705Eb3904e5FA65D5622cd237a2341FF',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x00CE54B988D8C44bFCae4026C17c37c69C490A12',
        vault: '0xaDA48ab8705Eb3904e5FA65D5622cd237a2341FF',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x49d446506D0f2db507AB4804563be9331BBc80E7',
        vault: '0xaDA48ab8705Eb3904e5FA65D5622cd237a2341FF',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x50D46c3BB529276aDe59a6678C14302D6B61C853',
        vault: '0x62afad1Dc013A75eb3c1524ca8293C6de7760D89',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x50D46c3BB529276aDe59a6678C14302D6B61C853',
        vault: '0x62afad1Dc013A75eb3c1524ca8293C6de7760D89',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xC7EE36E027272F11135792FaDE64D9365Cc583B5',
          symbol: 'weETHBULL',
          decimals: 18,
        },
        connector: '0x50D46c3BB529276aDe59a6678C14302D6B61C853',
        vault: '0x62afad1Dc013A75eb3c1524ca8293C6de7760D89',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x1a22ec8Af98c39075afDf671a1e52bb569F74215',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xF6c475d2aB23d84e45AD3634C8956dCDe27315E0',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x6Ee9b6ad1c97AdeeD071fd5f349cE65f91e43333',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
    ],
  },
}

export const weETHCMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7B35b4c05a90Ea5f311AeC815BE4148b446a68a2',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0xec68928bd83B2E52fF5A8e8c215B6ea72879F521',
    symbol: 'weETHC',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xCc958F84DaF36d3eC20BcBee7E99C073B882efc3',
        vault: '0x8180EcCC825b692ef65FF099a0A387743788bf78',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8F8c29B0271D4c60435DaF148df4a84edcB21c98',
        vault: '0xF982c812099d03AFFa0c8062aa1abcb584c23329',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xBd282333710B9C7e33E8a37d027885A7C079Ae23',
        vault: '0x3FBFD80EF7591658d1D7DdEC067F413eFd6f985c',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcAe44C93f7B3b519Fc28f9d4F7Ae22dE770a907b',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x945958CbA0081add5F9aef0baBB925b03847F2bF',
        vault: '0x76ee38B66A2BEF69E32fE070CFEc24Bf7F754Db6',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0xdE45E2bCCb99E0ed1a2876cFC51a71ca5e822641',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x6EE40DC88912cB30EBDa7c7C0C5B0113347B7f87',
        vault: '0x76ee38B66A2BEF69E32fE070CFEc24Bf7F754Db6',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x1cbbC18CB128AA470733eD29938Ab4878B0BEF20',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0xFE71489dD286561Eef7d2121BCA09090492d6fC0',
        vault: '0x76ee38B66A2BEF69E32fE070CFEc24Bf7F754Db6',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcAe44C93f7B3b519Fc28f9d4F7Ae22dE770a907b',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x3f66F272d33B764960779a301c4183306ae50e10',
        vault: '0xe7cD9370CdE6C9b5eAbCe8f86d01822d3de205A0',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0xdE45E2bCCb99E0ed1a2876cFC51a71ca5e822641',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x96E47fC9c701E8EEF22Fa53e62dc3eBBDcCAEa73',
        vault: '0x4061DF0f966Da2E7d973F9f532df87DD7a38DF75',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x1cbbC18CB128AA470733eD29938Ab4878B0BEF20',
          symbol: 'weETHC',
          decimals: 18,
        },
        connector: '0x44ed9cE901B367B1EF9DDBD4974C82A514c50DEc',
        vault: '0x8D2ec8D3033568ff42E4b39aF3F700250B255d96',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x1a22ec8Af98c39075afDf671a1e52bb569F74215',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xF6c475d2aB23d84e45AD3634C8956dCDe27315E0',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x6Ee9b6ad1c97AdeeD071fd5f349cE65f91e43333',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
    ],
  },
}

export const weETHCSMainnetAddresses: YieldTokenAddresses = {
  lyraInputToken: {
    address: '0x7B35b4c05a90Ea5f311AeC815BE4148b446a68a2',
    symbol: 'weETH',
    decimals: 18,
  },
  lyraOutputToken: {
    address: '0x12ab0242b3e8d4502FebCED6C2d52fD23F7262af',
    symbol: 'weETHCS',
    decimals: 18,
  },
  mintConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xCc958F84DaF36d3eC20BcBee7E99C073B882efc3',
        vault: '0x8180EcCC825b692ef65FF099a0A387743788bf78',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x8F8c29B0271D4c60435DaF148df4a84edcB21c98',
        vault: '0xF982c812099d03AFFa0c8062aa1abcb584c23329',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xBd282333710B9C7e33E8a37d027885A7C079Ae23',
        vault: '0x3FBFD80EF7591658d1D7DdEC067F413eFd6f985c',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0x91e3489da66eD36ebe0Be1013D87449447FD2bFa',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0x41218E4A8EAb7f1e9F28f4Ce4f644834a4CD8045',
        vault: '0xdBd48e52c5E7F56B3c31F73688De91BD30109c79',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0x85afa764A366d70d241513e3cBDAdd97A9A74e21',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xC22162aDFA4A6e1B58F7Df9eCc7186a24c329043',
        vault: '0xdBd48e52c5E7F56B3c31F73688De91BD30109c79',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0xb7F56c1a952D3AE664A83971BFfa5c1706947dBD',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xA42088f1099944db222EB1C985cbb7e7afF04609',
        vault: '0xdBd48e52c5E7F56B3c31F73688De91BD30109c79',
      },
    ],
  },
  redeemConfig: {
    inputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        inputToken: {
          address: '0x91e3489da66eD36ebe0Be1013D87449447FD2bFa',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0x1b882b9E87ABd7DD9B9b689Bee10Ed6a040033D0',
        vault: '0x790b0c8D071e57255E6d8b0092154c702AeA73dd',
      },
      {
        network: DepositNetwork.Base,
        inputToken: {
          address: '0x85afa764A366d70d241513e3cBDAdd97A9A74e21',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xA07EB173d58F7aF2b0267F2B5f6a091E01c17f85',
        vault: '0x41218E4A8EAb7f1e9F28f4Ce4f644834a4CD8045',
      },
      {
        network: DepositNetwork.Arbitrum,
        inputToken: {
          address: '0xb7F56c1a952D3AE664A83971BFfa5c1706947dBD',
          symbol: 'weETHCS',
          decimals: 18,
        },
        connector: '0xC6ea9524058a4E8B312B87cE3040F81ce9840ff1',
        vault: '0xdBd48e52c5E7F56B3c31F73688De91BD30109c79',
      },
    ],
    outputConfigs: [
      {
        network: DepositNetwork.Ethereum,
        outputToken: {
          address: '0xcd5fe23c85820f7b72d0926fc9b05b43e359b7ee',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x1a22ec8Af98c39075afDf671a1e52bb569F74215',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Base,
        outputToken: {
          address: '0x04C0599Ae5A44757c0af6F9eC3b93da8976c150A',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0xF6c475d2aB23d84e45AD3634C8956dCDe27315E0',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
      {
        network: DepositNetwork.Arbitrum,
        outputToken: {
          address: '0x35751007a407ca6feffe80b3cb397736d2cf4dbe',
          symbol: 'weETH',
          decimals: 18,
        },
        connector: '0x6Ee9b6ad1c97AdeeD071fd5f349cE65f91e43333',
        vault: '0xf58fF1Adc4d045e712a6D91e69d93B4092516659',
      },
    ],
  },
}
